// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

const composeDataUri = (data, mimeType = 'data:application/json;charset=utf-8') =>
  `${mimeType},${encodeURIComponent(data)}`

const downloadDataUriAsFile = (dataUri, filename) => {
  const node = document.createElement('a')
  node.setAttribute('href', dataUri)
  node.setAttribute('download', filename)
  document.body.appendChild(node) // Required for Firefox.
  node.click()
  node.remove()
}

export { composeDataUri, downloadDataUriAsFile }
