// Copyright © 2022 The Things Industries B.V.

import { createFetchingSelector } from '@ttn-lw/lib/store/selectors/fetching'
import { createErrorSelector } from '@ttn-lw/lib/store/selectors/error'

import { GET_OWN_TENANT_BASE } from '@console/store/actions/tenant.tti'

const selectOwnTenantStore = state => state.tenant

export const selectOwnTenant = state => selectOwnTenantStore(state).tenant
export const selectOwnTenantError = createErrorSelector(GET_OWN_TENANT_BASE)
export const selectOwnTenantFetching = createFetchingSelector(GET_OWN_TENANT_BASE)

export const selectOwnTenantConfig = state => {
  const tenant = selectOwnTenant(state)

  if (!Boolean(tenant)) {
    return undefined
  }

  return tenant
}
