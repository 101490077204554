// Copyright © 2022 The Things Industries B.V.

import React from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { defineMessages } from '@formatjs/intl'

import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import Breadcrumbs from '@ttn-lw/components/breadcrumbs'
import PageTitle from '@ttn-lw/components/page-title'

import Message from '@ttn-lw/lib/components/message'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import TenantSettingsForm from '@console/containers/tenant-settings-form/index.tti'

import Require from '@console/lib/components/require'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { mayConfigureTenantSettings } from '@console/lib/feature-checks'

import { getIsConfiguration } from '@console/store/actions/identity-server'
import { getOwnTenant } from '@console/store/actions/tenant.tti'

import style from './admin-tenant-settings.tti.styl'

const m = defineMessages({
  tenantSettingInfoText:
    'In this panel you can configure general settings of The Things Stack, such as user registration rules, global rights, or network configuration. The settings will be applied for this tenant only. It can take several minutes for changes to become effective.',
})

const TenantSettings = () => {
  useBreadcrumbs(
    'admin.tenant-settings',
    <Breadcrumb path={'/admin/tenant-settings'} content={sharedMessages.tenantSettings} />,
  )

  return (
    <RequireRequest requestAction={[getIsConfiguration(), getOwnTenant('configuration,name')]}>
      <Require featureCheck={mayConfigureTenantSettings} otherwise={{ redirect: '/' }}>
        <Breadcrumbs />
        <Container>
          <Row>
            <Col md={8} sm={12}>
              <PageTitle title={sharedMessages.tenantSettings} />
              <div className={style.introduction}>
                <Message content={m.tenantSettingInfoText} className={style.info} />
              </div>
            </Col>
          </Row>
          <TenantSettingsForm />
        </Container>
      </Require>
    </RequireRequest>
  )
}

export default TenantSettings
