// Copyright © 2022 The Things Industries B.V.

import { GET_OWN_TENANT_SUCCESS, GET_OWN_TENANT } from '@console/store/actions/tenant.tti'

const defaultState = {
  tenant: undefined,
}

const tenant = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OWN_TENANT:
      return {
        ...state,
        tenant: undefined,
      }

    case GET_OWN_TENANT_SUCCESS:
      return {
        ...state,
        tenant: payload,
      }
    default:
      return state
  }
}

export default tenant
