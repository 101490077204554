// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export default [
  'apiKeys',
  'api_keys',
  'app_s_key',
  'attributes',
  'contact_info',
  'dev_eui',
  'email',
  'email_address',
  'eui',
  'f_nwk_s_int_key',
  'join_eui',
  'keys',
  'nwk_s_enc_key',
  'password',
  'primary_email_address',
  's_nwk_s_int_key',
]
