// Copyright © 2021 The Things Industries B.V.

import React from 'react'
import { Container, Col, Row } from 'react-grid-system'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import AWSIoTImage from '@assets/misc/aws-iot-logo.png'

import PageTitle from '@ttn-lw/components/page-title'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import { withBreadcrumb } from '@ttn-lw/components/breadcrumbs/context'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'
import ErrorView from '@ttn-lw/lib/components/error-view'

import AWSIoTIntegrationForm from '@console/containers/aws-iot-integration-form/index.tti'

import Require from '@console/lib/components/require'

import SubViewError from '@console/views/sub-view-error'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { mayViewOrEditApplicationPackages } from '@console/lib/feature-checks'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'

import style from './application-integrations-aws-iot.tti.styl'

const m = defineMessages({
  awsIoTDefaultIntegration: 'AWS IoT Default Integration',
  awsIoTIntegration: 'AWS IoT integration',
  configuration: 'Configuration',
  deploymentGuide: 'Deployment Guide',
  infoText: `The AWS IoT integration supports streaming data, creating and claiming end devices, advanced security and metrics. This integration comes with an AWS CloudFormation template to deploy in your AWS account. Click Deployment Guide below to learn how to deploy the CloudFormation template.`,
})

const AWSIoTIntegration = () => {
  const appId = useSelector(selectSelectedApplicationId)
  return (
    <Require
      featureCheck={mayViewOrEditApplicationPackages}
      otherwise={{ redirect: `/applications/${appId}` }}
    >
      <ErrorView ErrorComponent={SubViewError}>
        <Container>
          <PageTitle title={m.awsIoTIntegration} />
          <Row>
            <Col lg={8} md={12}>
              <img className={style.logo} src={AWSIoTImage} alt="AWS IoT" />
              <Message content={m.infoText} className={style.info} />
              <div>
                <Message
                  component="h4"
                  content={sharedMessages.furtherResources}
                  className={style.furtherResources}
                />
                <Link.DocLink path="/integrations/aws-iot/default/" secondary>
                  <Message content={m.awsIoTDefaultIntegration} />
                </Link.DocLink>
                {' | '}
                <Link.DocLink path="/integrations/aws-iot/default/deployment-guide/" secondary>
                  <Message content={m.deploymentGuide} />
                </Link.DocLink>
              </div>
              <hr className={style.hRule} />
              <Message component="h3" content={m.configuration} />
              <AWSIoTIntegrationForm />
            </Col>
          </Row>
        </Container>
      </ErrorView>
    </Require>
  )
}

export default withBreadcrumb('apps.single.integrations.aws-iot', props => {
  const { appId } = props

  return (
    <Breadcrumb
      path={`/applications/${appId}/integrations/aws-iot`}
      content={sharedMessages.awsIoT}
    />
  )
})(AWSIoTIntegration)
