// Copyright © 2022 The Things Industries B.V.

import React, { useCallback, useState } from 'react'
import { defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import AZURE_IOT_CENTRAL_INTEGRATION from '@console/constants/azure-iot-central-integration.tti'

import Form from '@ttn-lw/components/form'
import Input from '@ttn-lw/components/input'
import ModalButton from '@ttn-lw/components/button/modal-button'
import SubmitBar from '@ttn-lw/components/submit-bar'
import SubmitButton from '@ttn-lw/components/submit-button'
import toast from '@ttn-lw/components/toast'

import {
  AzureIoTMessages,
  AzureIoTApplicationUpFilterSchema,
  AzureIoTDefaultApplicationUpFilterValues,
  CreateAzureIoTApplicationUpFilterField,
} from '@console/containers/azure-iot-integration/shared/index.tti'

import Yup from '@ttn-lw/lib/yup'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import { isNotFoundError } from '@ttn-lw/lib/errors/utils'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

import {
  setAppPkgDefaultAssoc,
  deleteAppPkgDefaultAssoc,
} from '@console/store/actions/application-packages'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'
import {
  selectApplicationPackageDefaultAssociation,
  selectGetApplicationPackagesError,
} from '@console/store/selectors/application-packages'

const m = defineMessages({
  accessKey: 'Azure Device Provisioning Service access key',
  accessKeyDescription: 'Provide the access key to be used for device provisioning',
  delete: 'Delete Azure IoT Central integration',
  deleted: 'Azure IoT Central integration deleted',
  deleteWarning: 'Are you sure you want to delete the integration?',
  enable: 'Enable Azure IoT Central integration',
  enabled: 'Azure IoT Central integration enabled',
  scopeId: 'Azure Device Provisioning Service scope ID',
  scopeIdDescription: 'Provide the scope ID to be used for device provisioning',
  update: 'Update Azure IoT Central integration',
  updated: 'Azure IoT Central integration updated',
})

const validationSchema = Yup.object()
  .shape({
    data: Yup.object().shape({
      access_key: Yup.string().required(sharedMessages.validateRequired),
      scope_id: Yup.string().required(sharedMessages.validateRequired),
      reported_properties: AzureIoTApplicationUpFilterSchema,
      send_event: AzureIoTApplicationUpFilterSchema,
    }),
  })
  .noUnknown()

const defaultValues = {
  data: {
    access_key: '',
    scope_id: '',
    reported_properties: AzureIoTDefaultApplicationUpFilterValues,
    send_event: AzureIoTDefaultApplicationUpFilterValues,
  },
}

const promisifiedSetAppPkgDefaultAssoc = attachPromise(setAppPkgDefaultAssoc)
const promisifiedDeleteAppPkgDefaultAssoc = attachPromise(deleteAppPkgDefaultAssoc)

const AzureIoTCentralIntegrationForm = () => {
  const [error, setError] = useState(undefined)
  const appId = useSelector(selectSelectedApplicationId)

  const dispatch = useDispatch()
  const defaultAssociation = useSelector(state =>
    selectApplicationPackageDefaultAssociation(state, AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PORT),
  )
  const packageError = useSelector(selectGetApplicationPackagesError)
  const initialValues = validationSchema.cast(defaultAssociation || defaultValues)

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(
          promisifiedSetAppPkgDefaultAssoc(appId, AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PORT, {
            package_name: AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PACKAGE_NAME,
            ...values,
          }),
        )
        toast({
          message: Boolean(defaultAssociation) ? m.updated : m.enabled,
          type: toast.types.SUCCESS,
        })
      } catch (error) {
        setError(error)
      }
    },
    [appId, dispatch, defaultAssociation],
  )

  const handleDelete = useCallback(async () => {
    try {
      await dispatch(
        promisifiedDeleteAppPkgDefaultAssoc(appId, AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PORT, {
          package_name: AZURE_IOT_CENTRAL_INTEGRATION.DEFAULT_PACKAGE_NAME,
        }),
      )
      toast({
        title: sharedMessages.azureIoTCentral,
        message: m.deleted,
        type: toast.types.SUCCESS,
      })
    } catch (error) {
      setError(error)
    }
  }, [appId, dispatch])

  if (packageError && !isNotFoundError(packageError)) {
    throw packageError
  }

  return (
    <Form
      error={error}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <Form.Field
        name="data.scope_id"
        title={m.scopeId}
        component={Input}
        description={m.scopeIdDescription}
        required
      />
      <Form.Field
        name="data.access_key"
        title={m.accessKey}
        component={Input}
        description={m.accessKeyDescription}
        sensitive
        required
      />
      {CreateAzureIoTApplicationUpFilterField({
        name: 'data.reported_properties',
        title: AzureIoTMessages.reportedPropertiesFilter,
        info: AzureIoTMessages.reportedPropertiesFilterInfo,
      })}
      {CreateAzureIoTApplicationUpFilterField({
        name: 'data.send_event',
        title: AzureIoTMessages.eventFilter,
        info: AzureIoTMessages.eventFilterInfo,
      })}
      <SubmitBar>
        <Form.Submit
          component={SubmitButton}
          message={Boolean(defaultAssociation) ? m.update : m.enable}
        />
        {Boolean(defaultAssociation) && (
          <ModalButton
            type="button"
            message={m.delete}
            modalData={{
              message: m.deleteWarning,
            }}
            onApprove={handleDelete}
            icon="delete"
            danger
            naked
          />
        )}
      </SubmitBar>
    </Form>
  )
}

export default AzureIoTCentralIntegrationForm
