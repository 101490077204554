// Copyright © 2022 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'

import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import { getOrganizationId } from '@ttn-lw/lib/selectors/id'
import PropTypes from '@ttn-lw/lib/prop-types'

import { createOrganization } from '@console/store/actions/organizations'

import OrganizationForm from './form'

const OrganizationAddForm = ({ onSuccess }) => {
  const [error, setError] = useState(undefined)
  const dispatch = useDispatch()

  const handleSubmit = useCallback(
    async values => {
      try {
        const organization = await dispatch(attachPromise(createOrganization(values)))
        onSuccess(getOrganizationId(organization))
      } catch (error) {
        setError(error)
      }
    },
    [dispatch, onSuccess],
  )

  return <OrganizationForm onSubmit={handleSubmit} error={error} />
}

OrganizationAddForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default OrganizationAddForm
