// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export default (ob, path) => {
  const paths = path.split('.')
  if (paths.length === 1) {
    return ob[path]
  }

  let current = ob

  for (const level of paths) {
    const isObject = typeof current[level] === 'object' && !(current[level] instanceof Array)
    if (!isObject) {
      return current[level]
    }
    current = current[level]
  }

  return current
}
