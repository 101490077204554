// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'
import { Container, Col, Row } from 'react-grid-system'

import Link from '@ttn-lw/components/link'
import PageTitle from '@ttn-lw/components/page-title'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import { withBreadcrumb } from '@ttn-lw/components/breadcrumbs/context'

import Message from '@ttn-lw/lib/components/message'
import ErrorView from '@ttn-lw/lib/components/error-view'

import StorageIntegrationPanel from '@console/containers/storage-integration-panel'

import Require from '@console/lib/components/require'

import SubViewError from '@console/views/sub-view-error'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { mayViewOrEditApplicationPackages } from '@console/lib/feature-checks'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'

import style from './application-integrations-storage.styl'

const m = defineMessages({
  storageIntegrationInfo: `The Storage Integration allows storing received upstream messages in a persistent database, and retrieving them at a later time. This integration is implemented as an <DocLink>Application Package</DocLink> and can be enabled per application or per end device.`,
  storageIntegrationApi: 'Storage Integration API',
})

const Storage = () => {
  const appId = useSelector(selectSelectedApplicationId)
  return (
    <Require
      featureCheck={mayViewOrEditApplicationPackages}
      otherwise={{ redirect: `/applications/${appId}` }}
    >
      <ErrorView ErrorComponent={SubViewError}>
        <Container>
          <PageTitle title={sharedMessages.storageIntegration} />
          <Row>
            <Col lg={8} md={12}>
              <Message
                content={m.storageIntegrationInfo}
                className={style.info}
                values={{
                  DocLink: msg => (
                    <Link.DocLink secondary path="/reference/application-packages" key="1">
                      {msg}
                    </Link.DocLink>
                  ),
                }}
              />
              <div>
                <Message
                  component="h4"
                  content={sharedMessages.furtherResources}
                  className={style.furtherResources}
                />
                <Link.DocLink path="/integrations/storage" secondary>
                  <Message content={sharedMessages.storageIntegration} />
                </Link.DocLink>
                {' | '}
                <Link.DocLink path="/reference/application-packages" secondary>
                  <Message content={sharedMessages.applicationPackages} />
                </Link.DocLink>
              </div>
              <hr className={style.hRule} />
              <StorageIntegrationPanel />
            </Col>
          </Row>
        </Container>
      </ErrorView>
    </Require>
  )
}

export default withBreadcrumb('apps.single.integrations.storage', props => {
  const { appId } = props

  return (
    <Breadcrumb
      path={`/applications/${appId}/integrations/storage`}
      content={sharedMessages.storageIntegration}
    />
  )
})(Storage)
