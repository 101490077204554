// Copyright © 2020 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'

import PropTypes from '@ttn-lw/lib/prop-types'
import getByPath from '@ttn-lw/lib/get-by-path'

import messages from '../messages'

import DescriptionList from './shared/description-list'
import JSONPayload from './shared/json-payload'

const GatewayStatusPreview = React.memo(({ event }) => {
  const metrics = getByPath(event, 'data.metrics')
  const versions = getByPath(event, 'data.versions')

  return (
    <DescriptionList>
      {Boolean(metrics) && (
        <DescriptionList.Item title={messages.metrics}>
          <JSONPayload data={metrics} />
        </DescriptionList.Item>
      )}
      {Boolean(versions) && (
        <DescriptionList.Item title={messages.versions}>
          <JSONPayload data={versions} />
        </DescriptionList.Item>
      )}
    </DescriptionList>
  )
})

GatewayStatusPreview.propTypes = {
  event: PropTypes.event.isRequired,
}

export default GatewayStatusPreview
