// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import Yup from '@ttn-lw/lib/yup'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { delay as delayRegexp } from '@console/lib/regexp'

const validationSchema = Yup.object().shape({
  frequency_plan_id: Yup.string(),
  schedule_downlink_late: Yup.boolean().default(false),
  schedule_anytime_delay: Yup.string().matches(
    delayRegexp,
    Yup.passValues(sharedMessages.validateDelayFormat),
  ),
})

export default validationSchema
