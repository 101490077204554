// Copyright © 2022 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

/* eslint-disable import/prefer-default-export */

import { selectAsConfig, selectJsConfig, selectNsConfig } from '@ttn-lw/lib/selectors/env'
import getHostnameFromUrl from '@ttn-lw/lib/host-from-url'

export const isOtherClusterApp = app => {
  let isOtherCluster
  if (app.application_server_address || app.network_server_address || app.join_server_address) {
    isOtherCluster =
      getHostnameFromUrl(selectAsConfig().base_url) !== app.application_server_address &&
      getHostnameFromUrl(selectNsConfig().base_url) !== app.network_server_address &&
      getHostnameFromUrl(selectJsConfig().base_url) !== app.join_server_address

    return isOtherCluster
  }

  return undefined
}
