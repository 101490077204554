// Copyright © 2022 The Things Industries B.V.

import tts from '@console/api/tts'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'
import { selectOwnTenantId } from '@ttn-lw/lib/selectors/env'

import * as tenant from '@console/store/actions/tenant.tti'

const validate = ({ action }, allow, reject) => {
  if (!selectOwnTenantId()) {
    return reject()
  }

  allow(action)
}

const getOwnTenantLogic = createRequestLogic({
  validate,
  type: tenant.GET_OWN_TENANT,
  process: ({ action }) => {
    const {
      meta: { selector },
    } = action

    const tenantId = selectOwnTenantId()

    return tts.Tenants.getById(tenantId, selector)
  },
})

const updateOwnTenantLogic = createRequestLogic({
  validate,
  type: tenant.UPDATE_OWN_TENANT,
  process: ({ action }) => {
    const {
      payload: { patch },
    } = action

    const tenantId = selectOwnTenantId()

    return tts.Tenants.updateById(tenantId, patch)
  },
})

export default [getOwnTenantLogic, updateOwnTenantLogic]
