// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { isPlainObject } from 'lodash'
import { Tooltip } from 'react-leaflet'

import Message from '@ttn-lw/lib/components/message'

import sharedMessages from '@ttn-lw/lib/shared-messages'

export default locations =>
  isPlainObject(locations)
    ? Object.keys(locations).map(key => ({
        position: {
          latitude: locations[key].latitude || 0,
          longitude: locations[key].longitude || 0,
        },
        accuracy: locations[key].accuracy,
        children: (
          <Message
            component={Tooltip}
            direction="top"
            content={
              key === 'user'
                ? sharedMessages.locationMarkerDescriptionUser
                : sharedMessages.locationMarkerDescriptionNonUser
            }
          />
        ),
      }))
    : []
