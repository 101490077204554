// Copyright © 2022 The Things Industries B.V.

import { defineMessages } from 'react-intl'

export default defineMessages({
  adminRightsAll: 'Grant all rights to admins',
  endDevicePictureDisableUpload: 'Disable user upload of end device pictures',
  profilePictureDisableUpload: 'Disable profile picture uploads',
  profilePictureDisableUploadDesc: 'Uploading end device pictures is currently CLI only',
  profilePictureUseGravatar: 'Enable Gravatar for profile pictures',
  profilePictureUseGravatarDesc:
    'Allows users to use a <Link>Gravatar</Link> associated with their email address',
  userRegistration: 'User registration',
  userRegistrationDesc: 'Customize how new user accounts are created, handled and validated.',
  userRegistrationAdminApproval: 'Require admin approval of new users',
  userRegistrationContactInfoValidationRequired: 'Require validation of user contact information',
  userRegistrationContactInfoValidationDesc:
    'If enabled, users will have to verify their emails before usage',
  userRegistrationAdminApprovalDesc:
    'If enabled, unapproved users will not be automatically approved',
  userRegistrationEnabled: 'Enable user registration',
  userRegistrationEnabledDescription:
    'Disabling user registration will remove the registration screen; admins can still create new users manually',
  userRegistrationInvitationRequired: 'Allow user registration via invitation only',
  userRegistrationInvitationDesc: 'Invitations can be sent from admins (currently CLI only)',
  userRegistrationInvitationTokenTTL: 'Duration of the invitation token',
  invitationTokenDesc:
    'Determines how long the invitation token is valid after being sent via email',
  passwordRequirements: 'Password requirements',
  passwordRequirementsDesc: 'Control security characteristics of user defined passwords.',
  userRegistrationPasswordMaxLength: 'Max. length',
  userRegistrationPasswordMinDigits: 'Min. digits',
  userRegistrationPasswordMinLength: 'Min. length',
  userRegistrationPasswordMinSpecial: 'Min. special characters',
  userRegistrationPasswordMinUppercase: 'Min. uppercase characters',
  userRights: 'User rights',
  userRightsDesc: 'Manage global rights for regular users and administrators.',
  userRightsCreateApplications: 'Users may create applications',
  userRightsCreateClients: 'Users may create OAuth clients',
  userRightsCreateGateways: 'Users may create gateways',
  userRightsCreateOrganizations: 'Users may create organizations',
  networkSettings: 'Network settings',
  networkSettingsDesc: 'Manage deduplication windows and end device address prefixes.',
  cooldownWindow: 'Cooldown window',
  deduplicationWindow: 'Deduplication window',
  addPrefix: 'Add prefix',
  addPrefixPlaceholder: 'e.g. 26000000/7',
  devAddrPrefixes: 'Device address prefixes',
  brandingBaseUrl: 'Branding base URL',
  brandingBaseUrlDesc:
    'Controls where branding assets are sourced from. See <Link>Web UI Branding</Link> for more information.',
  branding: 'Branding',
  brandingDesc: 'Enable custom branded assets.',
  uploads: 'Uploads',
  uploadsDesc: 'Restrict or enable user image uploads and Gravatar settings.',
  useDefaultSettings: 'Use global default settings',
  useDefaultSettingsDesc: 'Global defaults are set by deployment owners',
  successMessage:
    'Tenant settings updated. It can take some minutes for these changes to become effective.',
  adminRightsAllDesc:
    'Grant access to all information (including keys) of entities not owned by admins',
  devAddrPrefixesEmptyValidation: 'Prefixes may not be empty. Please remove empty entries.',
  devAddrPrefixesFormatValidation: 'Prefixes must be in the format "00000000/0"',
})
